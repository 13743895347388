<template>
  <div class="detail">
    <div class="header">
      <div class="title">排水户详情</div>

      <div class="extra" @click="cancel">
        <a-icon type="close" />
      </div>
    </div>

    <div class="content" style="padding-top: 2vh; padding-bottom: 2vh">
      <div class="item">
        <div class="label">企业名称：</div>
        <div class="value">
          {{ detail.name }}
        </div>
      </div>

      <div class="item">
        <div class="label">法人：</div>
        <div class="value">
          {{ detail.legalPerson }}
        </div>
      </div>

      <div class="item">
        <div class="label">位置：</div>
        <div class="value">
          {{ detail.location }}
        </div>
      </div>
      <div class="item">
        <div class="label">经纬度：</div>
        <div class="value">{{ detail.x }},{{ detail.y }}</div>
      </div>

      <div class="item">
        <div class="label">企业状态：</div>
        <div v-if="String(detail.status) === '1'">正常</div>
        <div v-if="String(detail.status) === '0'">禁用</div>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>
  
  
<style lang="less" scoped>
.detail {
  max-height: 40vh;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.7);

  width: 50vw;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px;

  & > .header {
    background-color: var(--theme-color);
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1vh 1vw;
    line-height: 4vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .content {
    padding: 0 1vw;
    display: flex;
    flex-direction: column;
    gap: 1vh;

    .item {
      display: flex;
      align-items: center;

      .label {
        min-width: 4em;
      }

      .value {
        color: var(--theme-color);
      }
    }
  }

  .center {
    padding-top: 4vh;
    padding-bottom: 4vh;
  }
}
</style>