import request from '@/api/request'
import { LineLayer } from '@antv/l7'

function fetchPipe(data) {
    return request({
        url: '/model-analysis/pipe/sewage/locations',
        method: 'post',
        data
    })
}

export default {

    methods: {

        getPipe() {

            fetchPipe({}).then((res) => {
                if (res && Array.isArray(res.locs)) {

                    const list = res.locs.map(item => {
                        return {
                            "type": "Feature",
                            "properties": {
                                id: item[0],
                                startLng: item[1],
                                startLat: item[2],
                                endLng: item[3],
                                endLat: item[4]
                            },
                            "geometry": {
                                "type": "LineString",
                                "coordinates": [
                                    [parseFloat(item[1]), parseFloat(item[2])],
                                    [parseFloat(item[3]), parseFloat(item[4])],
                                ]
                            }
                        };
                    });


                    const sources = {
                        "type": "FeatureCollection",
                        "features": list
                    }

                    const pipeLayer = new LineLayer({
                        zIndex: 10,
                        depth: true
                    }).source(sources).size(1)
                        .shape('line')
                        .color('#FFA500')
                        .animate({
                            interval: 1, // 间隔
                            duration: 2, // 持续时间，延时
                            trailLength: 2 // 流线长度
                        });

                    this.pipeLayer = pipeLayer;
                    this.scene?.addLayer(pipeLayer);


                }
            });

        },

    }
}

