<template>
  <div>
    <div id="map"></div>

    <div class="left-panel">
      <Detail v-if="visible" :detail="detail" @cancel="cancel" @trace="trace" />
      <CompanyDetail
        v-if="companyVisible"
        :detail="companyDetail"
        @cancel="cancelCompany"
      />
    </div>
  </div>
</template>
<script>
import Detail from "./components/detail.vue";
import CompanyDetail from "./components/company-detail.vue";

import { Scene } from "@antv/l7";
import { GaodeMapV2 } from "@antv/l7-maps";

import sewagePipe from "./mixins/sewage-pipe";
import request from "@/api/request";

import waterQualityMarkerImage from "@/assets/assets/bottom-bar/water-monitor-active.png";
import alertImage from "@/assets/assets/bottom-bar/alert-active.png";

export default {
  mixins: [sewagePipe],

  components: {
    Detail,
    CompanyDetail,
  },

  data() {
    return {
      list: [],

      detail: {},
      visible: false,

      companyVisible: false,
      companyDetail: {},
    };
  },

  mounted() {
    this.initMap();

    this.scene = new Scene({
      id: "map",
      logoVisible: false,
      map: new GaodeMapV2({
        mapInstance: this.map,
      }),
    });

    this.scene.on("loaded", () => {
      this.getPipe();
      this.getList();
    });
  },

  beforeDestroy() {
    this.markers?.forEach((item) => {
      item.off("click", this.onMarkerClick);
    });

    this.companyMarkers?.forEach((item) => {
      item.off("click", this.onCompanyMarkerClick);
    });

    if (this.infoWindow) {
      this.infoWindow.close();
    }

    this.map?.clearMap();
    this.map?.destroy();

    this.scene?.removeAllLayer();
    this.scene?.destroy();
  },

  methods: {
    initMap() {
      const { AMap } = window;
      if (!AMap) {
        return;
      }
      var map = new AMap.Map("map", {
        center: [119.056708, 33.583976],
        zoom: 16,
        mapStyle: "amap://styles/52faa27d18984923cceae498330eceb1",
      });

      this.map = map;
    },

    getList() {
      const hide = this.$message.loading("加载中...");
      request({
        url: "/model-analysis/pipe/device/query/alert/type/waterquality",
      })
        .then((res) => {
          if (res && Array.isArray(res) && res.length > 0) {
            this.list = Object.freeze(res);
            this.addMarker();
          }
        })
        .finally(() => {
          hide();
        });
    },

    addMarker() {
      if (Array.isArray(this.markers)) {
        this.markers.forEach((item) => {
          item.off("click", this.onMarkerClick);
          this.map?.remove(item);
        });
      }

      const markers = this.list.map((item) => {
        let _errorMessage = "";

        let icon = new window.AMap.Icon({
          image: waterQualityMarkerImage,
          imageSize: [24, 24],
        });

        if (Array.isArray(item.alert) && item.alert.length > 0) {
          if (item.alert[0].value) {
            _errorMessage = item.alert[0].remark;
            icon = new window.AMap.Icon({
              image: alertImage,
              imageSize: [24, 24],
            });
          }
        }

        const marker = new window.AMap.Marker({
          position: [item.x, item.y],
          icon,
          extData: {
            ...item,
            _errorMessage,
          },
        });

        marker.on("click", this.onMarkerClick);
        return marker;
      });

      this.map.add(markers);
      this.markers = markers;
    },

    onMarkerClick(e) {
      this.cancel();
      console.log(e);
      this.detail = e.target._opts.extData;
      this.visible = true;
    },

    cancel() {
      this.detail = {};
      this.visible = false;

      this.companyVisible = false;
      this.companyDetail = {};

      // 清空范围和公司标记点
      this.addCompanyMarker();
      this.removeCircle();

      if (this.infoWindow) {
        this.infoWindow.close();
      }
    },

    cancelCompany() {
      this.companyVisible = false;
      this.companyDetail = {};
      if (this.infoWindow) {
        this.infoWindow.close();
      }
    },

    // 溯源
    trace() {
      request({
        url:
          "/model-analysis/pipe/sewageDischarger/queryListByDevice/" +
          this.detail.id,
      })
        .then((res) => {
          console.log("res", res);
          if (Array.isArray(res)) {
            this.addCompanyMarker(res);

            const { x, y } = this.detail;

            console.log("this.detail", this.detail);
            const originalLnglat = [x, y];

            let max = 0; // 米

            res.forEach((item) => {
              const targetLnglat = [item.x, item.y];
              const length = window.AMap.GeometryUtil.distance(
                originalLnglat,
                targetLnglat
              );
              if (length > max) {
                max = length;
              }
            });

            console.log("max", max);

            this.addCircle(max + 10);
          }
        })
        .finally(() => {
          this.companyVisible = false;
          this.companyDetail = {};
        });
    },

    removeCircle() {
      if (this.circle) {
        this.map.remove(this.circle);
      }
    },

    addCircle(radius) {
      this.removeCircle();
      //设置圆形位置
      var center = new window.AMap.LngLat(this.detail.x, this.detail.y);

      // 创建圆形 Circle实例
      var circle = new window.AMap.Circle({
        center: center, //圆心
        radius: radius, //半径
        borderWeight: 3,
        strokeOpacity: 0,
        fillOpacity: 0.2,
        // 线样式还支持 'dashed'
        fillColor: "#01C8E3",
        zIndex: 0,
      });

      this.circle = circle;
      this.map.add(circle);
    },

    addCompanyMarker(list = []) {
      if (Array.isArray(this.companyMarkers)) {
        this.companyMarkers.forEach((item) => {
          item.off("click", this.onCompanyMarkerClick);
          this.map?.remove(item);
        });
      }

      const markers = list.map((item) => {
        // let icon = new window.AMap.Icon({
        //     image: waterQualityMarkerImage,
        //     imageSize: [24, 24]
        // })

        const marker = new window.AMap.Marker({
          position: [item.x, item.y],
          // icon,
          extData: item,
          zIndex: 50,
        });

        marker.on("click", this.onCompanyMarkerClick);
        return marker;
      });

      this.map.add(markers);
      this.companyMarkers = markers;
    },

    onCompanyMarkerClick(e) {
      this.cancelCompany();

      console.log(e);
      this.companyDetail = e.target._opts.extData;
      this.companyVisible = true;

      this.setInfoWindow({
        title: this.companyDetail.name,
        lng: this.companyDetail.x,
        lat: this.companyDetail.y,
      });
    },

    setInfoWindow({ title, lng, lat }) {
      if (this.infoWindow) {
        this.infoWindow.close();
      }
      const infoWindow = new window.AMap.InfoWindow({
        content: title,
        offset: new window.AMap.Pixel(0, -24),
      });

      var position = new window.AMap.LngLat(lng, lat);

      infoWindow.open(this.map, position);

      this.infoWindow = infoWindow;
    },
  },
};
</script>
  
  
<style lang="less" scoped>
#map {
  position: fixed;
  height: 105vh;
  width: 100vw;
  left: 0;
  top: 0;
}

.left-panel {
  position: fixed;
  top: 11vh;
  left: 1vw;
  z-index: 99;
}
</style>