<template>
  <div class="detail">
    <div class="header">
      <div class="title">水质监测站详情</div>

      <div class="extra" @click="cancel">
        <a-icon type="close" />
      </div>
    </div>

    <div
      class="content"
      style="padding-top: 2vh; padding-bottom: 2vh; position: relative"
    >
      <Navigator :lng="detail.x" :lat="detail.y" />

      <div class="item">
        <div class="label">编号：</div>
        <div class="value">{{ detail.code }}</div>
      </div>
      <div class="item">
        <div class="label">名称：</div>
        <div class="value">{{ detail.name }}</div>
      </div>
      <div class="item">
        <div class="label">报警：</div>
        <div class="value" style="color: red" v-if="detail._errorMessage">
          {{ detail._errorMessage }}
        </div>
        <div class="value" v-else>--</div>
      </div>

      <div class="item">
        <div class="label">溶解氧：</div>
        <div class="value">
          {{ realTimeData.doxygen ? realTimeData.doxygen + "mg/L" : "--" }}
        </div>
      </div>
      <div class="item">
        <div class="label">透明度：</div>
        <div class="value">
          {{ realTimeData.ss ? realTimeData.ss + "cm" : "--" }}
        </div>
      </div>
      <div class="item">
        <div class="label">氧化还原电位：</div>
        <div class="value">
          {{ realTimeData.orp ? realTimeData.orp + "mV" : "--" }}
        </div>
      </div>

      <div class="item">
        <div class="label">化学需氧量：</div>
        <div class="value">
          {{ realTimeData.cod ? realTimeData.cod + "mg/L" : "--" }}
        </div>
      </div>

      <div class="item">
        <div class="label">酸碱度：</div>
        <div class="value">
          {{ realTimeData.ph ? realTimeData.ph : "--" }}
        </div>
      </div>

      <div class="item">
        <div class="label">氨氮：</div>
        <div class="value">
          {{ realTimeData.nh3n ? realTimeData.nh3n + "mg/L" : "--" }}
        </div>
      </div>
      <div class="item">
        <div class="label">更新时间：</div>
        <div class="value">
          {{ realTimeData.ts ? realTimeData.ts : "--" }}
        </div>
      </div>
    </div>

    <div class="center" style="padding-bottom: 2vh" v-if="detail._errorMessage">
      <a-space>
        <a-button type="primary" @click="trace">溯源分析</a-button>
      </a-space>
    </div>
  </div>
</template>
  
<script>
import request from "@/api/request";
import Navigator from "@/components/navigator";

export default {
  components: {
    Navigator,
  },

  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  watch: {
    detail: {
      handler() {
        if (this.detail.sn) {
          this.getRealTimeData();
        }
      },
      deep: true,
    },
  },

  data() {
    return {
      realTimeData: {},
    };
  },

  mounted() {
    this.getRealTimeData();
  },

  methods: {
    getRealTimeData() {
      const hide = this.$message.loading("加载中...");
      request({
        url: "/data-service/dev/realTime",
        method: "post",
        data: {
          devType: this.detail.type, // 获取类型
          eids: [this.detail.sn],
        },
      })
        .then((res) => {
          this.realTimeData = res || {};
        })
        .finally(() => {
          hide();
        });
    },

    cancel() {
      this.$emit("cancel");
    },

    trace() {
      this.$emit("trace");
    },
  },
};
</script>
  
  
<style lang="less" scoped>
.detail {
  background-color: rgba(0, 0, 0, 0.7);

  width: 50vw;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  margin-bottom: 2vh;

  & > .header {
    background-color: var(--theme-color);
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1vh 1vw;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .content {
    padding: 0 1vw;
    display: flex;
    flex-direction: column;
    gap: 1vh;

    .item {
      display: flex;
      align-items: center;

      .label {
        min-width: 4em;
      }

      .value {
        color: var(--theme-color);
      }
    }
  }
}
</style>